exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-artist-js": () => import("./../../../src/pages/about-the-artist.js" /* webpackChunkName: "component---src-pages-about-the-artist-js" */),
  "component---src-pages-aesthetics-js": () => import("./../../../src/pages/aesthetics.js" /* webpackChunkName: "component---src-pages-aesthetics-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-the-artist-js": () => import("./../../../src/pages/contact-the-artist.js" /* webpackChunkName: "component---src-pages-contact-the-artist-js" */),
  "component---src-pages-index-full-js": () => import("./../../../src/pages/index_full.js" /* webpackChunkName: "component---src-pages-index-full-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-to-paint-js": () => import("./../../../src/pages/learn-to-paint.js" /* webpackChunkName: "component---src-pages-learn-to-paint-js" */),
  "component---src-pages-masonry-test-js": () => import("./../../../src/pages/masonry-test.js" /* webpackChunkName: "component---src-pages-masonry-test-js" */),
  "component---src-pages-mosaics-index-js": () => import("./../../../src/pages/mosaics/index.js" /* webpackChunkName: "component---src-pages-mosaics-index-js" */),
  "component---src-pages-mosaics-sun-mosaic-js": () => import("./../../../src/pages/mosaics/sun-mosaic.js" /* webpackChunkName: "component---src-pages-mosaics-sun-mosaic-js" */),
  "component---src-pages-oil-paintings-blue-hand-js": () => import("./../../../src/pages/oil-paintings/blue-hand.js" /* webpackChunkName: "component---src-pages-oil-paintings-blue-hand-js" */),
  "component---src-pages-oil-paintings-buy-crypto-js": () => import("./../../../src/pages/oil-paintings/buy-crypto.js" /* webpackChunkName: "component---src-pages-oil-paintings-buy-crypto-js" */),
  "component---src-pages-oil-paintings-cotswold-gatehouse-js": () => import("./../../../src/pages/oil-paintings/cotswold-gatehouse.js" /* webpackChunkName: "component---src-pages-oil-paintings-cotswold-gatehouse-js" */),
  "component---src-pages-oil-paintings-derbyshire-dry-stone-wall-js": () => import("./../../../src/pages/oil-paintings/derbyshire-dry-stone-wall.js" /* webpackChunkName: "component---src-pages-oil-paintings-derbyshire-dry-stone-wall-js" */),
  "component---src-pages-oil-paintings-derbyshire-farm-window-js": () => import("./../../../src/pages/oil-paintings/derbyshire-farm-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-derbyshire-farm-window-js" */),
  "component---src-pages-oil-paintings-gaia-js": () => import("./../../../src/pages/oil-paintings/gaia.js" /* webpackChunkName: "component---src-pages-oil-paintings-gaia-js" */),
  "component---src-pages-oil-paintings-gainsborough-old-hall-js": () => import("./../../../src/pages/oil-paintings/gainsborough-old-hall.js" /* webpackChunkName: "component---src-pages-oil-paintings-gainsborough-old-hall-js" */),
  "component---src-pages-oil-paintings-gawsworth-hall-js": () => import("./../../../src/pages/oil-paintings/gawsworth-hall.js" /* webpackChunkName: "component---src-pages-oil-paintings-gawsworth-hall-js" */),
  "component---src-pages-oil-paintings-haddon-hall-js": () => import("./../../../src/pages/oil-paintings/haddon-hall.js" /* webpackChunkName: "component---src-pages-oil-paintings-haddon-hall-js" */),
  "component---src-pages-oil-paintings-hands-of-the-artist-js": () => import("./../../../src/pages/oil-paintings/hands-of-the-artist.js" /* webpackChunkName: "component---src-pages-oil-paintings-hands-of-the-artist-js" */),
  "component---src-pages-oil-paintings-hanseatic-league-js": () => import("./../../../src/pages/oil-paintings/hanseatic-league.js" /* webpackChunkName: "component---src-pages-oil-paintings-hanseatic-league-js" */),
  "component---src-pages-oil-paintings-index-js": () => import("./../../../src/pages/oil-paintings/index.js" /* webpackChunkName: "component---src-pages-oil-paintings-index-js" */),
  "component---src-pages-oil-paintings-joust-js": () => import("./../../../src/pages/oil-paintings/joust.js" /* webpackChunkName: "component---src-pages-oil-paintings-joust-js" */),
  "component---src-pages-oil-paintings-lincoln-oriel-window-js": () => import("./../../../src/pages/oil-paintings/lincoln-oriel-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-lincoln-oriel-window-js" */),
  "component---src-pages-oil-paintings-lions-head-fountain-js": () => import("./../../../src/pages/oil-paintings/lions-head-fountain.js" /* webpackChunkName: "component---src-pages-oil-paintings-lions-head-fountain-js" */),
  "component---src-pages-oil-paintings-meditating-woman-js": () => import("./../../../src/pages/oil-paintings/meditating-woman.js" /* webpackChunkName: "component---src-pages-oil-paintings-meditating-woman-js" */),
  "component---src-pages-oil-paintings-mother-nature-js": () => import("./../../../src/pages/oil-paintings/mother-nature.js" /* webpackChunkName: "component---src-pages-oil-paintings-mother-nature-js" */),
  "component---src-pages-oil-paintings-neptune-js": () => import("./../../../src/pages/oil-paintings/neptune.js" /* webpackChunkName: "component---src-pages-oil-paintings-neptune-js" */),
  "component---src-pages-oil-paintings-oxborough-hall-tudor-window-js": () => import("./../../../src/pages/oil-paintings/oxborough-hall-tudor-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-oxborough-hall-tudor-window-js" */),
  "component---src-pages-oil-paintings-oxborough-hall-window-js": () => import("./../../../src/pages/oil-paintings/oxborough-hall-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-oxborough-hall-window-js" */),
  "component---src-pages-oil-paintings-round-window-newstead-abbey-js": () => import("./../../../src/pages/oil-paintings/round-window-newstead-abbey.js" /* webpackChunkName: "component---src-pages-oil-paintings-round-window-newstead-abbey-js" */),
  "component---src-pages-oil-paintings-sleeping-kings-js": () => import("./../../../src/pages/oil-paintings/sleeping-kings.js" /* webpackChunkName: "component---src-pages-oil-paintings-sleeping-kings-js" */),
  "component---src-pages-oil-paintings-spider-colour-wheel-js": () => import("./../../../src/pages/oil-paintings/spider-colour-wheel.js" /* webpackChunkName: "component---src-pages-oil-paintings-spider-colour-wheel-js" */),
  "component---src-pages-oil-paintings-still-life-fruits-js": () => import("./../../../src/pages/oil-paintings/still-life-fruits.js" /* webpackChunkName: "component---src-pages-oil-paintings-still-life-fruits-js" */),
  "component---src-pages-oil-paintings-tree-of-life-js": () => import("./../../../src/pages/oil-paintings/tree-of-life.js" /* webpackChunkName: "component---src-pages-oil-paintings-tree-of-life-js" */),
  "component---src-pages-oil-paintings-venetian-window-js": () => import("./../../../src/pages/oil-paintings/venetian-window.js" /* webpackChunkName: "component---src-pages-oil-paintings-venetian-window-js" */),
  "component---src-pages-oil-paintings-windows-and-roses-js": () => import("./../../../src/pages/oil-paintings/windows-and-roses.js" /* webpackChunkName: "component---src-pages-oil-paintings-windows-and-roses-js" */),
  "component---src-pages-painting-technique-js": () => import("./../../../src/pages/painting-technique.js" /* webpackChunkName: "component---src-pages-painting-technique-js" */),
  "component---src-pages-printmaking-albert-einstein-js": () => import("./../../../src/pages/printmaking/albert-einstein.js" /* webpackChunkName: "component---src-pages-printmaking-albert-einstein-js" */),
  "component---src-pages-printmaking-hand-of-the-artist-js": () => import("./../../../src/pages/printmaking/hand-of-the-artist.js" /* webpackChunkName: "component---src-pages-printmaking-hand-of-the-artist-js" */),
  "component---src-pages-printmaking-index-js": () => import("./../../../src/pages/printmaking/index.js" /* webpackChunkName: "component---src-pages-printmaking-index-js" */),
  "component---src-pages-printmaking-les-paul-guitar-js": () => import("./../../../src/pages/printmaking/les-paul-guitar.js" /* webpackChunkName: "component---src-pages-printmaking-les-paul-guitar-js" */),
  "component---src-pages-printmaking-pine-cone-js": () => import("./../../../src/pages/printmaking/pine-cone.js" /* webpackChunkName: "component---src-pages-printmaking-pine-cone-js" */),
  "component---src-pages-printmaking-praying-hands-js": () => import("./../../../src/pages/printmaking/praying-hands.js" /* webpackChunkName: "component---src-pages-printmaking-praying-hands-js" */),
  "component---src-pages-printmaking-tyrannosaurus-rex-js": () => import("./../../../src/pages/printmaking/tyrannosaurus-rex.js" /* webpackChunkName: "component---src-pages-printmaking-tyrannosaurus-rex-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-video-diaries-js": () => import("./../../../src/pages/video-diaries.js" /* webpackChunkName: "component---src-pages-video-diaries-js" */),
  "component---src-pages-watercolours-cotswold-gatehouse-js": () => import("./../../../src/pages/watercolours/cotswold-gatehouse.js" /* webpackChunkName: "component---src-pages-watercolours-cotswold-gatehouse-js" */),
  "component---src-pages-watercolours-derbyshire-barn-js": () => import("./../../../src/pages/watercolours/derbyshire-barn.js" /* webpackChunkName: "component---src-pages-watercolours-derbyshire-barn-js" */),
  "component---src-pages-watercolours-index-js": () => import("./../../../src/pages/watercolours/index.js" /* webpackChunkName: "component---src-pages-watercolours-index-js" */),
  "component---src-pages-watercolours-little-moreton-hall-js": () => import("./../../../src/pages/watercolours/little-moreton-hall.js" /* webpackChunkName: "component---src-pages-watercolours-little-moreton-hall-js" */),
  "component---src-pages-watercolours-medieval-brick-window-js": () => import("./../../../src/pages/watercolours/medieval-brick-window.js" /* webpackChunkName: "component---src-pages-watercolours-medieval-brick-window-js" */),
  "component---src-pages-watercolours-medieval-door-js": () => import("./../../../src/pages/watercolours/medieval-door.js" /* webpackChunkName: "component---src-pages-watercolours-medieval-door-js" */),
  "component---src-pages-watercolours-timber-framed-study-js": () => import("./../../../src/pages/watercolours/timber-framed-study.js" /* webpackChunkName: "component---src-pages-watercolours-timber-framed-study-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-group-js": () => import("./../../../src/templates/tag-group.js" /* webpackChunkName: "component---src-templates-tag-group-js" */)
}

